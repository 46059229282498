import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

const SuccessPage = () => (
  <Layout>
    <div id="success">
      <h1>Your message has been sent</h1>
      <p>We will contact you as soon as possible</p>
      <Link to="/">Go back</Link>
    </div>
  </Layout>
)

export default SuccessPage
